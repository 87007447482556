import React, { useState, useEffect, useRef } from 'react';
import { auth, db, storage } from '../firebase';
import { updateProfile, updateEmail, sendEmailVerification } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import { upgradeSessionToFullUser } from './sessionManagement';
import { handleGuestUpgradeOnProfileUpdate } from './userManagement';
import EmailChangeModal from '../emails/EmailChangeModal';
import UserAvatar from './UserAvatar';
import { useNotification } from '../components/NotificationContext';
import { uploadImage } from '../utils/imageUtils';
import { useRequireAuth } from '../hooks/useRequireAuth';
import { updateUserInfoAcrossCollections } from '../utils/userUpdateUtils';

const Profile = () => {
  const { showNotification } = useNotification();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [isEmailChangeModalOpen, setIsEmailChangeModalOpen] = useState(false);
  const [isProfileChanged, setIsProfileChanged] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const navigate = useNavigate();
  const { user, setUser, isAuthenticated, isGuest } = useAuth();
  const fileInputRef = useRef(null);

  useEffect(() => {
    console.log('Profile component mounted. Authentication status:', isAuthenticated);
    console.log('Current user:', user);

    if (!isAuthenticated) {
      console.log('User is not authenticated. Redirecting to /whoops');
      navigate('/whoops');
    } else if (user && user.uid) {
      console.log('User is authenticated. Setting up profile data.');
      setName(user.name || '');
      setEmail(user.email || '');
    } else {
      console.error('User or user.uid is undefined');
      showNotification('Error: User information is missing', 'error');
    }
  }, [isAuthenticated, user, navigate, showNotification]);

  useEffect(() => {
    if (user) {
      setIsProfileChanged(name !== user.name || email !== user.email || avatar !== null);
    }
  }, [name, email, avatar, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated || !user) {
      showNotification('User is not authenticated', 'error');
      return;
    }

    try {
      console.log('Starting profile update...', user);
      const updates = { 
        name: name.trim(),
        isGuest: false // Set isGuest to false when profile is updated
      };
      
      if (avatar) {
        console.log('Uploading new avatar...');
        const avatarRef = ref(storage, `avatars/${user.uid}`);
        await uploadBytes(avatarRef, avatar);
        const photoURL = await getDownloadURL(avatarRef);
        updates.photoURL = photoURL;
        console.log('Avatar uploaded successfully:', photoURL);
      }

      // First update Firebase Auth profile
      if (auth.currentUser) {
        console.log('Updating auth profile...');
        await updateProfile(auth.currentUser, updates);
      }

      console.log('Updating user info across collections...');
      await updateUserInfoAcrossCollections(user.uid, {
        ...updates,
        email: user.email, // Include email in case it's needed for updates
      });

      const updatedUser = { ...user, ...updates };
      setUser(updatedUser);

      if (isGuest) {
        console.log('Handling guest upgrade...');
        await handleGuestUpgradeOnProfileUpdate(user.uid, name, email);
        await upgradeSessionToFullUser(user.uid);
      }

      showNotification('Profile updated successfully', 'success');
      setIsProfileChanged(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      showNotification('Error updating profile: ' + error.message, 'error');
    }
  };

  const handleEmailChange = async (newEmail) => {
    try {
      await updateEmail(auth.currentUser, newEmail);
      await sendEmailVerification(auth.currentUser);
      
      // Update email across all collections
      await updateUserInfoAcrossCollections(user.uid, {
        email: newEmail,
        name: user.name,
        photoURL: user.photoURL,
      });

      setEmail(newEmail);
      setUser({ ...user, email: newEmail });
      showNotification('Email updated. Please check your inbox for verification.', 'success');
    } catch (error) {
      console.error('Error updating email:', error);
      showNotification('Error updating email: ' + error.message, 'error');
    }
    setIsEmailChangeModalOpen(false);
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAvatar(file);
      setSelectedFileName(file.name);
      setIsProfileChanged(true);
    } else {
      setAvatar(null);
      setSelectedFileName('');
    }
  };

  const handleAvatarButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveAvatar = async () => {
    if (!user || !user.uid) return;

    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { photoURL: null });

      if (auth.currentUser) {
        await updateProfile(auth.currentUser, { photoURL: null });
      }

      const avatarRef = ref(storage, `avatars/${user.uid}`);
      await deleteObject(avatarRef);

      const updatedUser = { ...user, photoURL: null };
      setUser(updatedUser);
      setSelectedFileName('');
      showNotification('Profile image removed successfully', 'success');
    } catch (error) {
      console.error('Error removing profile image:', error);
      showNotification('Error removing profile image: ' + error.message, 'error');
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const path = `profileImages/${user.uid}/${Date.now()}_${file.name}`;
        const photoURL = await uploadImage(file, path);
        await updateProfile(user, { photoURL });
        setUser({ ...user, photoURL });
        showNotification('Profile picture updated successfully', 'success');
      } catch (error) {
        console.error('Error uploading image:', error);
        showNotification('Error uploading image: ' + error.message, 'error');
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-8 px-4">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Profile</h2>
      {user ? (
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6 space-y-6">
          <div className="flex items-center space-x-6">
            <UserAvatar user={user} size="xl" />
            <div className="flex flex-col space-y-2">
              <input
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
                className="hidden"
                ref={fileInputRef}
              />
              <button
                type="button"
                onClick={handleAvatarButtonClick}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {user.photoURL ? "Change profile image" : "Add profile image"}
              </button>
              {selectedFileName && (
                <span className="text-sm text-gray-600">Selected: {selectedFileName}</span>
              )}
              {user.photoURL && (
                <button
                  type="button"
                  onClick={handleRemoveAvatar}
                  className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Remove profile image
                </button>
              )}
            </div>
          </div>
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setIsProfileChanged(true);
              }}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setIsProfileChanged(true);
              }}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <button
              type="submit"
              className={`w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                !isProfileChanged ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={!isProfileChanged}
            >
              Update Profile
            </button>
          </div>
        </form>
      ) : (
        <p className="text-gray-600">Loading user profile...</p>
      )}
      <EmailChangeModal
        isOpen={isEmailChangeModalOpen}
        onClose={() => setIsEmailChangeModalOpen(false)}
        onConfirm={handleEmailChange}
        currentEmail={email}
      />
    </div>
  );
};

export default Profile;
