import { db } from '../firebase';
import { collection, query, where, getDocs, doc, updateDoc, writeBatch } from 'firebase/firestore';

export const updateUserInfoAcrossCollections = async (userId, updatedInfo) => {
  console.log(`Updating user info for userId: ${userId}`, updatedInfo);
  const batch = writeBatch(db);

  // Update in Users collection
  const userRef = doc(db, 'users', userId);
  batch.update(userRef, updatedInfo);

  // Update in RSVPs
  const rsvpsQuery = query(collection(db, 'rsvps'), where('userId', '==', userId));
  const rsvpDocs = await getDocs(rsvpsQuery);
  rsvpDocs.forEach(doc => {
    const rsvpUpdates = {};
    if (updatedInfo.email !== undefined) rsvpUpdates.email = updatedInfo.email;
    if (Object.keys(rsvpUpdates).length > 0) {
      batch.update(doc.ref, rsvpUpdates);
    }
  });

  // Update in Groups
  const groupsQuery = query(collection(db, 'groups'));
  const groupDocs = await getDocs(groupsQuery);
  groupDocs.forEach(groupDoc => {
    const members = groupDoc.data().members;
    const updatedMembers = members.map(member => {
      if (member.uid === userId) {
        const updatedMember = { ...member };
        if (updatedInfo.email !== undefined) updatedMember.email = updatedInfo.email;
        if (updatedInfo.name !== undefined) updatedMember.name = updatedInfo.name;
        if (updatedInfo.photoURL !== undefined) updatedMember.photoURL = updatedInfo.photoURL;
        return updatedMember;
      }
      return member;
    });
    
    // Only update if there are actual changes
    if (JSON.stringify(members) !== JSON.stringify(updatedMembers)) {
      batch.update(groupDoc.ref, { members: updatedMembers });
    }
  });

  // Update in Events
  const eventsQuery = query(collection(db, 'events'), where('createdBy', '==', userId));
  const eventDocs = await getDocs(eventsQuery);
  eventDocs.forEach(doc => {
    const eventUpdates = {};
    if (updatedInfo.email !== undefined) eventUpdates.hostEmail = updatedInfo.email;
    if (updatedInfo.name !== undefined) eventUpdates.hostName = updatedInfo.name;
    if (Object.keys(eventUpdates).length > 0) {
      batch.update(doc.ref, eventUpdates);
    }
  });

  // Commit the batch
  await batch.commit();
  console.log('User info update completed successfully');
};

// Add this new function
export const updateGroupMembershipsByEmail = async (email, userId) => {
  console.log(`Updating group memberships for email: ${email}, userId: ${userId}`);
  if (!email || !userId) {
    console.error('Invalid email or userId provided');
    return;
  }

  const groupsRef = collection(db, 'groups');
  const q = query(groupsRef);
  const querySnapshot = await getDocs(q);

  console.log(`Checking ${querySnapshot.size} groups for membership updates`);

  const batch = writeBatch(db);
  let updatedGroupsCount = 0;

  querySnapshot.forEach((doc) => {
    const groupRef = doc.ref;
    const members = doc.data().members || [];
    let needsUpdate = false;
    const updatedMembers = members.map(member => {
      if (member.email === email) {
        needsUpdate = true;
        return { 
          ...member, 
          uid: userId,
          // Only update these fields if they're not already set
          name: member.name || '',
          photoURL: member.photoURL || '',
          // Add isGuest field if it doesn't exist
          isGuest: member.isGuest !== undefined ? member.isGuest : false
        };
      }
      return member;
    });

    if (needsUpdate) {
      batch.update(groupRef, { members: updatedMembers });
      updatedGroupsCount++;
    }
  });

  if (updatedGroupsCount > 0) {
    try {
      await batch.commit();
      console.log(`Group membership update completed. Updated ${updatedGroupsCount} groups.`);
    } catch (error) {
      console.error('Error committing batch update:', error);
      throw error;
    }
  } else {
    console.log('No groups needed updating.');
  }
};
