import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { auth, db } from './firebase';
import { 
  signInWithEmailLink, 
  isSignInWithEmailLink, 
  sendSignInLinkToEmail,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword
} from 'firebase/auth';
import { 
  doc, 
  getDoc,
  onSnapshot
} from 'firebase/firestore';
import { 
  getSession, 
  createSession, 
  clearSession,
  refreshSession,
  getSessionUser,
  upgradeSessionToFullUser
} from './users/sessionManagement';
import { 
  createOrUpdateUserDocument, 
  upgradeGuestUserToFull, 
  isGuestUser,
  getUserProfileByEmail,
  createGuestUserDocument,
  sendSignInLinkToExistingUser,
  sendWelcomeEmailIfNeeded
} from './users/userManagement';
import { handleRSVPAfterSignIn, addRSVP } from './events/eventServices';
import { useNotification } from './components/NotificationContext';
import { setPersistence, browserLocalPersistence } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { checkGroupMembership, joinGroup } from './groups/groupServices';
import { updateGroupMembershipsByEmail } from './utils/userUpdateUtils';
export const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const updateUser = useCallback((updatedUser) => {
    if (updatedUser && (updatedUser.uid || updatedUser.id)) {
      const finalUser = {
        ...updatedUser,
        uid: updatedUser.uid || updatedUser.id
      };
      setUser(prevUser => {
        if (JSON.stringify(prevUser) !== JSON.stringify(finalUser)) {
          console.log('Updating user:', finalUser);
          return finalUser;
        }
        return prevUser;
      });
    } else {
      console.error('Attempted to update user with invalid data:', updatedUser);
      setUser(null);
    }
  }, []);

  const refreshUser = useCallback(async () => {
    if (user && user.email) {
      const refreshedUser = await getUserProfileByEmail(user.email);
      if (refreshedUser && JSON.stringify(refreshedUser) !== JSON.stringify(user)) {
        updateUser(refreshedUser);
      }
    }
  }, [user, updateUser]);

  useEffect(() => {
    const initializeAuth = async () => {
      await setPersistence(auth, browserLocalPersistence);
      
      const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
        console.log('Auth state changed. User:', firebaseUser ? firebaseUser.email : 'null', 'Stack trace:', new Error().stack);
        if (firebaseUser) {
          console.log('Calling createOrUpdateUserDocument for:', firebaseUser.email);
          const userDoc = await createOrUpdateUserDocument(firebaseUser);
          console.log('createOrUpdateUserDocument completed for:', firebaseUser.email);
          updateUser(userDoc);
          createSession(firebaseUser.email, false);
          
          if (userDoc.isNewUser) {
            await sendWelcomeEmailIfNeeded(userDoc);
          }
          
          await updateGroupMembershipsByEmail(firebaseUser.email, firebaseUser.uid);
        } else {
          const session = getSession();
          if (session) {
            const sessionUser = await getSessionUser();
            if (sessionUser) {
              updateUser(sessionUser);
            } else {
              setUser(null);
              clearSession();
            }
          } else {
            setUser(null);
          }
        }
        setLoading(false);
      });

      return () => unsubscribe();
    };

    initializeAuth();
  }, [updateUser]);

  useEffect(() => {
    const handleEmailLink = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        try {
          // First verify the action code is valid
          const actionCode = new URLSearchParams(window.location.search).get('oobCode');
          if (!actionCode) {
            throw new Error('Invalid sign-in link');
          }

          // Get email from localStorage where it was stored when requesting the link
          let email = window.localStorage.getItem('emailForSignIn');
          
          // If no email in localStorage, this might be a different browser/device
          if (!email) {
            // In this case, we should prompt the user to enter their email
            // This is actually a security feature - prevents link forwarding
            email = window.prompt('Please enter the email address you used to request the sign-in link');
            if (!email) {
              showNotification('Email is required to complete sign-in.', 'error');
              navigate('/login');
              return;
            }
          }

          console.log('Attempting to sign in with email:', email);
          const result = await signInWithEmailLink(auth, email, window.location.href);
          console.log('Sign in successful:', result);
          
          // Clear the email from localStorage after successful sign in
          window.localStorage.removeItem('emailForSignIn');

          // Wait for auth state to be updated
          await new Promise(resolve => setTimeout(resolve, 1000));
          
          // Get the current user after auth state is updated
          const currentUser = auth.currentUser;
          if (!currentUser) {
            throw new Error('Failed to get current user after sign in');
          }

          let updatedUser;
          if (await isGuestUser(email)) {
            updatedUser = await upgradeGuestUserToFull(email);
          } else {
            updatedUser = await createOrUpdateUserDocument(currentUser);
          }
          updateUser(updatedUser);
          upgradeSessionToFullUser();
          refreshSession();

          // Update group memberships
          await updateGroupMembershipsByEmail(email, currentUser.uid);

          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.get('rsvp') === 'true') {
            const eventUrl = urlParams.get('eventId');
            if (eventUrl) {
              await handleRSVPAfterSignIn(email, eventUrl, showNotification);
              // After RSVP, redirect to the event page
              navigate(`/event/${eventUrl}`);
            }
          } else {
            // If not an RSVP, redirect to my events
            navigate('/my-events');
          }

          showNotification('You have successfully signed in!', 'success');
          
          // Clear the URL to prevent repeated sign-in attempts
          window.history.replaceState({}, document.title, window.location.pathname);
        } catch (error) {
          console.error('Error signing in with email link:', error);
          if (error.code === 'auth/invalid-action-code') {
            showNotification('The sign-in link has expired or already been used. Please request a new one.', 'error');
          } else if (error.code === 'auth/missing-email') {
            showNotification('No email was provided for sign-in. Please try again.', 'error');
          } else if (error.code === 'auth/email-already-in-use') {
            // This error shouldn't occur with magic links, but if it does, we should handle it gracefully
            showNotification('An account already exists with this email. Please try signing in again.', 'error');
          } else if (error.message === 'Invalid sign-in link') {
            showNotification('Invalid sign-in link. Please request a new one.', 'error');
          } else {
            showNotification('Error signing in: ' + error.message, 'error');
          }
          navigate('/login');
        }
      }
    };

    handleEmailLink();
  }, [showNotification, navigate]);

  const mockSignIn = async (email) => {
    console.log(`Mock sign-in for ${email}`);
    let userDoc = await getUserProfileByEmail(email);
    if (!userDoc) {
      return { success: false, message: 'User not found. Please create a test account first.' };
    }
    const mockUser = {
      ...userDoc,
      uid: userDoc.uid,
      email,
      isGuest: false,
      isTestAccount: false,
    };
    updateUser(mockUser);
    createSession(email, false);

    // Update group memberships
    console.log('Updating group memberships after mock sign-in');
    await updateGroupMembershipsByEmail(email, mockUser.uid);

    return { success: true, message: 'Mock sign-in successful!', user: mockUser };
  };

  const realFirebaseSignIn = async (email, isRSVP = false, eventId = null) => {
    console.log(`Attempting to send sign-in link to email: ${email}`);
    try {
      const actionCodeSettings = {
        url: window.location.origin + '/login' + (isRSVP ? `?rsvp=true&eventId=${eventId}` : ''),
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      console.log('Sign-in link sent successfully');
      return { success: true, message: 'Check your email for the login link!' };
    } catch (error) {
      console.error('Error sending sign-in link:', error);
      return { success: false, message: 'Error sending sign-in link: ' + error.message };
    }
  };

  const login = async (email, useMockSignIn = false, isRSVP = false, eventId = null) => {
    console.log(`Login attempt for email: ${email}, useMockSignIn: ${useMockSignIn}, isRSVP: ${isRSVP}`);
    try {
      if (useMockSignIn && process.env.NODE_ENV === 'development') {
        console.log('Using mock sign-in');
        return await mockSignIn(email);
      }

      const userDoc = await getUserProfileByEmail(email);
      if (userDoc) {
        console.log('Existing user found:', userDoc);
        // For existing users, send a sign-in link
        const result = await realFirebaseSignIn(email, isRSVP, eventId);
        if (result.success) {
          showNotification('Please check your email for the login link.', 'success');
        }
        return result;
      } else {
        console.log('No existing user found, creating guest account');
        // New user (guest)
        const newUser = await createGuestUserDocument(email);
        createSession(email, true);
        updateUser(newUser);
        return { success: true, message: 'Guest account created!', user: newUser, isNewUser: true };
      }
    } catch (error) {
      console.error('Error during login process:', error);
      return { success: false, message: 'Error during login process: ' + error.message };
    }
  };

  const handleRSVP = async (email, eventId) => {
    console.log(`Handling RSVP for email: ${email}, eventId: ${eventId}`);
    try {
      const userDoc = await getUserProfileByEmail(email);
      
      if (userDoc) {
        // Existing user: send sign-in link
        const result = await sendSignInLinkToExistingUser(email, eventId);
        if (result.success) {
          showNotification(result.message, 'info'); // Change 'error' to 'info'
        } else {
          showNotification(result.message, 'error');
        }
        return result;
      } else {
        // New user: create guest profile and session
        const newUser = await createGuestUserDocument(email);
        createSession(email, true);
        updateUser(newUser);
        await addRSVP(email, eventId);
        showNotification('Guest account created and RSVP confirmed!', 'success');
        return { success: true, message: 'Guest account created and RSVP confirmed!', user: newUser };
      }
    } catch (error) {
      console.error('Error during RSVP process:', error);
      showNotification('Error during RSVP process: ' + error.message, 'error');
      return { success: false, message: 'Error during RSVP process: ' + error.message };
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      clearSession();
      setUser(null);
      showNotification('Logged out successfully', 'success');
    } catch (error) {
      console.error('Error during logout:', error);
      showNotification('Error during logout: ' + error.message, 'error');
    }
  };

  const checkGroupAccess = async (groupName) => {
    if (!user) return false;
    return await checkGroupMembership(user.uid, groupName);
  };

  const joinGroupWithCode = async (groupName, email, groupCode) => {
    const result = await joinGroup(groupName, email, groupCode);
    if (result.success) {
      await refreshUser();
    }
    return result;
  };

  const value = {
    user,
    setUser: updateUser,  // Make sure this line is present
    login,
    logout,
    handleRSVP,
    isAuthenticated: !!user,
    isGuest: user?.isGuest || false,
    isTestUser: user?.isTestAccount || false,
    refreshUser,
    checkGroupAccess,
    joinGroupWithCode,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
