import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, getDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ConfirmationModal from '../components/ConfirmationModal';
import { useAuth } from '../Auth';
import { useNotification } from '../components/NotificationContext';
import DOMPurify from 'dompurify';
import { formatDateTime } from '../utils/dateUtils';
import { getInitials } from '../utils/imageUtils';
import AddEventModal from './AddEventModal';
import EventCard from './EventCard';

const MyEvents = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [isRemoveRsvpModalOpen, setIsRemoveRsvpModalOpen] = useState(false);
  const [rsvpToRemove, setRsvpToRemove] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const { showNotification } = useNotification();
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    private: false,
    groups: [],
    timeFrame: 'all'
  });
  const [sortOrder, setSortOrder] = useState('desc');
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('created');
  const [createdEventsCount, setCreatedEventsCount] = useState(0);
  const [rsvpdEventsCount, setRsvpdEventsCount] = useState(0);

  useEffect(() => {
    if (isAuthenticated) {
      fetchEvents(user);
    } else {
      navigate('/whoops');
    }
  }, [navigate, user, isAuthenticated]);

  useEffect(() => {
    filterEvents();
  }, [events, activeFilters, sortOrder, searchTerm, activeTab]);

  const fetchEvents = async (currentUser) => {
    setLoading(true);
    try {
      // First fetch user's RSVPs
      const rsvpsQuery = query(
        collection(db, 'rsvps'),
        where('userId', '==', currentUser.uid)
      );
      
      const rsvpSnapshot = await getDocs(rsvpsQuery);
      const rsvps = rsvpSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          rsvpId: doc.id,
          eventId: data.eventId,
          userId: data.userId
        };
      });

      // Then fetch all events
      const eventsQuery = collection(db, 'events');
      const eventSnapshot = await getDocs(eventsQuery);

      let eventList = eventSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        isCreated: doc.data().createdBy === currentUser.uid,
        isRSVPd: false,
        rsvpId: null
      }));

      // Update RSVP status for each event
      eventList = eventList.map((event) => {
        const rsvp = rsvps.find(rsvp => rsvp.eventId === event.id);
        return {
          ...event,
          isRSVPd: !!rsvp,
          rsvpId: rsvp ? rsvp.rsvpId : null
        };
      });

      // Fetch all groups
      const allGroupsQuery = query(collection(db, 'groups'));
      const allGroupsSnapshot = await getDocs(allGroupsQuery);

      const userGroups = allGroupsSnapshot.docs
        .filter(doc => doc.data().members.some(member => member.uid === currentUser.uid))
        .map(doc => ({
          id: doc.id,
          name: doc.data().name,
          eventIds: doc.data().eventIds || []
        }));

      setAllGroups(userGroups);
      setEvents(eventList);
      setCreatedEventsCount(eventList.filter(event => event.isCreated).length);
      setRsvpdEventsCount(eventList.filter(event => event.isRSVPd).length);
    } catch (error) {
      showNotification('Error fetching events: ' + error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const filterEvents = () => {
    let filtered = [...events];

    if (activeTab === 'created') {
      filtered = filtered.filter(event => event.isCreated);
    } else if (activeTab === 'rsvpd') {
      filtered = filtered.filter(event => event.isRSVPd);
    }

    if (activeFilters.private) {
      filtered = filtered.filter(event => event.isPrivate);
    }

    if (activeFilters.groups.length > 0) {
      filtered = filtered.filter(event => 
        event.groupIds && event.groupIds.some(groupId => activeFilters.groups.includes(groupId))
      );
    }

    // Apply time frame filter
    const now = new Date();
    switch (activeFilters.timeFrame) {
      case 'upcoming':
        filtered = filtered.filter(event => new Date(event.startDate) > now);
        break;
      case 'past':
        filtered = filtered.filter(event => new Date(event.endDate) < now);
        break;
      // 'all' doesn't need filtering
    }

    // Apply search
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(event =>
        event.name.toLowerCase().includes(lowerSearchTerm) ||
        event.description.toLowerCase().includes(lowerSearchTerm)
      );
    }

    // Apply sorting
    filtered.sort((a, b) => {
      const dateA = new Date(`${a.startDate}T${a.startTime}`);
      const dateB = new Date(`${b.startDate}T${b.startTime}`);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setFilteredEvents(filtered);
  };

  const handleDelete = (eventId) => {
    setEventToDelete(eventId);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteDoc(doc(db, 'events', eventToDelete));
      setEvents(events.filter(event => event.id !== eventToDelete));
      showNotification('Event deleted successfully', 'success');
    } catch (error) {
      showNotification('Error deleting event: ' + error.message, 'error');
    } finally {
      setIsDeleteModalOpen(false);
      setEventToDelete(null);
    }
  };

  const handleRemoveRsvp = (rsvpId, eventId) => {
    setRsvpToRemove({ rsvpId, eventId });
    setIsRemoveRsvpModalOpen(true);
  };

  const confirmRemoveRsvp = async () => {
    try {
      await deleteDoc(doc(db, 'rsvps', rsvpToRemove.rsvpId));
      setEvents(events.map(event => 
        event.id === rsvpToRemove.eventId 
          ? { ...event, isRsvped: false, rsvpId: null } 
          : event
      ));
      showNotification('RSVP removed successfully', 'success');
    } catch (error) {
      showNotification('Error removing RSVP: ' + error.message, 'error');
    } finally {
      setIsRemoveRsvpModalOpen(false);
      setRsvpToRemove(null);
    }
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const renderGroups = (event) => {
    if (!event.groupNames || event.groupNames.length === 0) return null;
    
    const displayedGroups = event.groupNames.slice(0, 3); // Display up to 3 groups
    
    return (
      <>
        {displayedGroups.map((name, index) => (
          <span key={index} className="px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded-full">
            {name}
          </span>
        ))}
        {event.groupNames.length > 3 && (
          <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded-full">
            +{event.groupNames.length - 3}
          </span>
        )}
      </>
    );
  };

  const isPastEvent = (event) => {
    return new Date(`${event.endDate}T${event.endTime || '23:59'}`) < new Date();
  };

  const handleFilterChange = (filterName, value) => {
    setActiveFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const handleEventSaved = (updatedEvent) => {
    setEvents(prevEvents => prevEvents.map(event => 
      event.id === updatedEvent.id ? updatedEvent : event
    ));
    showNotification('Event updated successfully', 'success');
  };

  if (loading) {
    return <div className="text-center py-4 mt-16">Loading your events...</div>;
  }

  return (
    <div className="w-full">
      <div className="bg-gray-100 border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold py-6">My Events</h1>
          <div className="flex">
            <button
              onClick={() => setActiveTab('created')}
              className={`py-3 px-4 text-center font-medium border-b-2 ${
                activeTab === 'created'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Created Events ({createdEventsCount})
            </button>
            <button
              onClick={() => setActiveTab('rsvpd')}
              className={`py-3 px-4 text-center font-medium border-b-2 ${
                activeTab === 'rsvpd'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              RSVP'd Events ({rsvpdEventsCount})
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-6">
          <div className="w-full md:w-1/2 relative">
            <input
              type="text"
              placeholder={`Search ${activeTab === 'created' ? 'my created' : 'RSVP\'d'} events...`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 pr-10 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={() => setIsFilterExpanded(!isFilterExpanded)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            >
              <i className="fas fa-filter"></i>
            </button>
          </div>
          {activeTab === 'created' }
        </div>

        {isFilterExpanded && (
          <div className="mb-6">
            <FilterSection
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              allGroups={allGroups}
              handleFilterChange={handleFilterChange}
            />
          </div>
        )}

        {filteredEvents.length === 0 ? (
          <div className="text-center py-8 bg-white shadow-md rounded-lg">
            <p className="text-xl text-gray-600">No events match your current search or filters.</p>
            <p className="text-gray-500 mt-2">Try adjusting your search term or filter selections{activeTab === 'created' ? ', or create a new event' : ''}.</p>
            {activeTab === 'created' && (
              <div className="mt-4">
                <button
                  onClick={() => setIsAddEventModalOpen(true)}
                  className="inline-block px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
                >
                  Create New Event
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {filteredEvents.map((event) => (
              <EventCard
                key={event.id}
                event={{
                  ...event,
                  isMyEvent: event.isCreated,
                  rsvpId: event.isRSVPd ? event.rsvpId : null
                }}
                mode="list"
                onEdit={handleEventSaved}
                onDelete={() => handleDelete(event.id)}
                onFilterChange={handleFilterChange}
                activeFilters={activeFilters}
              />
            ))}
          </div>
        )}
      </div>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this event?"
      />
      <ConfirmationModal
        isOpen={isRemoveRsvpModalOpen}
        onClose={() => setIsRemoveRsvpModalOpen(false)}
        onConfirm={confirmRemoveRsvp}
        message="Are you sure you want to remove your RSVP from this event?"
      />
      <AddEventModal
        isOpen={isAddEventModalOpen}
        onClose={() => setIsAddEventModalOpen(false)}
      />
    </div>
  );
};

const FilterSection = ({ 
  activeFilters, 
  setActiveFilters, 
  sortOrder, 
  setSortOrder, 
  allGroups,
  handleFilterChange
}) => {
  const [isGroupDropdownOpen, setIsGroupDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setIsGroupDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleGroupButtonClick = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      document.documentElement.style.setProperty('--button-bottom', `${buttonRect.bottom}px`);
    }
    setIsGroupDropdownOpen(!isGroupDropdownOpen);
  };

  return (
    <div className="mb-6">
      <div className="flex flex-wrap items-center gap-2 mb-2">
        {['all', 'upcoming', 'past'].map(timeFrame => (
          <button
            key={timeFrame}
            onClick={() => handleFilterChange('timeFrame', timeFrame)}
            className={`px-3 py-1 text-sm rounded-full transition-colors duration-200 ${
              activeFilters.timeFrame === timeFrame ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            {timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}
          </button>
        ))}
        <button
          onClick={() => handleFilterChange('private', !activeFilters.private)}
          className={`px-3 py-1 text-sm rounded-full transition-colors duration-200 ${
            activeFilters.private ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          Private
        </button>
        <div className="relative">
          <button
            ref={buttonRef}
            onClick={handleGroupButtonClick}
            className={`px-3 py-1 text-sm rounded-full transition-colors duration-200 ${
              activeFilters.groups.length > 0 ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Groups {activeFilters.groups.length > 0 && `(${activeFilters.groups.length})`}
          </button>
          {isGroupDropdownOpen && (
            <div 
              ref={dropdownRef}
              className="sm:absolute sm:right-0 sm:mt-1 sm:w-48 dropdown-mobile-center bg-white rounded-md shadow-lg"
            >
              <div className="max-h-60 overflow-y-auto">
                {allGroups.map((group) => (
                  <button
                    key={group.id}
                    onClick={() => {
                      handleFilterChange('groups', 
                        activeFilters.groups.includes(group.id)
                          ? activeFilters.groups.filter(id => id !== group.id)
                          : [...activeFilters.groups, group.id]
                      );
                    }}
                    className={`block w-full text-left px-4 py-2 text-sm ${
                      activeFilters.groups.includes(group.id) ? 'bg-blue-100 text-blue-800' : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    {group.name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <button
          onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
          className="px-3 py-1 text-sm rounded-full bg-gray-200 text-gray-700 hover:bg-gray-300 flex items-center"
        >
          {sortOrder === 'asc' ? 'Oldest First ↑' : 'Newest First ↓'}
        </button>
        <button
          onClick={() => {
            setActiveFilters({
              myEvents: false,
              rsvpd: false,
              private: false,
              groups: [],
              timeFrame: 'all'
            });
            setSortOrder('desc');
          }}
          className="px-3 py-1 text-sm rounded-full bg-red-500 text-white hover:bg-red-600"
        >
          Reset Filters
        </button>
      </div>
    </div>
  );
};

export default MyEvents;
