import { auth, db } from '../firebase';
import { 
  doc, 
  getDoc, 
  setDoc, 
  collection, 
  query, 
  where, 
  getDocs,
  serverTimestamp,
  writeBatch,
  updateDoc, 
  runTransaction
} from 'firebase/firestore';
import { sendSignInLinkToEmail, createUserWithEmailAndPassword } from 'firebase/auth';
import { sendWelcomeEmail } from '../emails/emailService';
import { updateGroupMembershipsByEmail } from '../utils/userUpdateUtils';

export const createOrUpdateUserDocument = async (user) => {
  console.log('createOrUpdateUserDocument called for:', user.email, 'Stack trace:', new Error().stack);
  const userRef = doc(db, 'users', user.uid);

  try {
    return await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userRef);
      
      if (!userDoc.exists()) {
        console.log('Creating new user document for:', user.email);
        const userData = {
          uid: user.uid,
          email: user.email,
          name: user.displayName || '',
          photoURL: user.photoURL || '',
          createdAt: serverTimestamp(),
          isGuest: user.isGuest || false,
          isTestAccount: user.isTestAccount || false,
          welcomeEmailSent: false,
        };
        transaction.set(userRef, userData);
        return { ...userData, isNewUser: true };
      } else {
        console.log('User document already exists for:', user.email);
        const existingData = userDoc.data();
        const updatedData = {
          ...existingData,
          uid: user.uid,
          email: user.email,
          name: existingData.name || user.displayName || '',
          photoURL: user.photoURL || existingData.photoURL || '',
          isGuest: existingData.isGuest !== undefined ? existingData.isGuest : false,
          isTestAccount: existingData.isTestAccount || false,
        };
        transaction.update(userRef, updatedData);
        return { ...updatedData, isNewUser: false };
      }
    });
  } catch (error) {
    console.error('Error in createOrUpdateUserDocument:', error);
    throw error;
  }
};

export const sendWelcomeEmailIfNeeded = async (userData) => {
  if (!userData.welcomeEmailSent) {
    console.log('Sending welcome email for user:', userData.email);
    await sendWelcomeEmail(userData.email, userData.name || 'New Gatherly User');
    await updateDoc(doc(db, 'users', userData.uid), { welcomeEmailSent: true });
  }
};

// Create a guest user when they RSVP without signing in
export const createGuestUserDocument = async (email) => {
  // Check if a user with this email already exists
  const userQuery = query(collection(db, 'users'), where('email', '==', email));
  const userSnapshot = await getDocs(userQuery);

  if (!userSnapshot.empty) {
    // User already exists, return the existing user data
    return userSnapshot.docs[0].data();
  }

  // Create a new user document with a generated ID
  const newUserRef = doc(collection(db, 'users'));
  const newUser = {
    uid: newUserRef.id,
    email,
    name: '',
    photoURL: '',
    isGuest: true,
    isTestAccount: false,
    createdAt: serverTimestamp(),
    welcomeEmailSent: false,
  };

  await setDoc(newUserRef, newUser);
  
  // Send welcome email for new guest users
  if (!newUser.welcomeEmailSent) {
    console.log('Sending welcome email for new guest user:', email);
    await sendWelcomeEmail(email, 'New Gatherly User');
    await updateDoc(newUserRef, { welcomeEmailSent: true });
  }
  
  return newUser;
};

// Upgrade a guest user to a full user
export const upgradeGuestUserToFull = async (uid, profileData = {}) => {
  const userRef = doc(db, 'users', uid);
  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    const existingData = userDoc.data();
    const updatedData = {
      ...existingData,
      ...profileData,
      isGuest: false,
    };
    // Remove undefined values
    Object.keys(updatedData).forEach((key) => updatedData[key] === undefined && delete updatedData[key]);
    await setDoc(userRef, updatedData, { merge: true });
    return updatedData;
  }
  return null;
};

// Function to handle RSVP for non-logged-in users
export const handleNonLoggedInRSVP = async (email, eventUrl) => {
  const userDoc = await getUserProfileByEmail(email);

  if (userDoc) {
    // Existing user: send sign-in link
    const result = await sendSignInLinkToExistingUser(email, eventUrl);
    return { shouldLogin: true, ...result };
  } else {
    // New user: create guest profile and session
    const newUser = await createGuestUserDocument(email);
    if (newUser) {
      return { newUser, shouldCreateSession: true };
    }
  }
  return false;
};

// Function to upgrade a guest based on profile update
export const handleGuestUpgradeOnProfileUpdate = async (email, profileData) => {
  // Upgrade user only if they are currently a guest
  const isGuest = await isGuestUser(email);
  if (isGuest) {
    return await upgradeGuestUserToFull(email, profileData);
  }
  return false;
};

// Check if user is a guest
export const isGuestUser = async (uid) => {
  const userRef = doc(db, 'users', uid);
  const userDoc = await getDoc(userRef);

  return userDoc.exists() && userDoc.data().isGuest === true;
};

// Retrieve the user profile by email
export const getUserProfileByEmail = async (email) => {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('email', '==', email));
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0];
    return { uid: doc.id, ...doc.data() };
  }
  
  return null;
};

// Function to handle test user creation and updates
export const createTestUserDocument = async (email, displayName) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, 'testpassword');
    const user = userCredential.user;

    const userRef = doc(db, 'users', user.uid);
    const userData = {
      uid: user.uid,
      email,
      name: displayName || '',
      photoURL: '',
      isGuest: false,  // Explicitly set to false
      isTestAccount: true,
      createdAt: serverTimestamp(),
    };

    await setDoc(userRef, userData);
    console.log('Test user created successfully:', userData);
    return userData;
  } catch (error) {
    console.error('Error creating test user:', error);
    throw error;
  }
};

// Send sign-in link to existing user
export const sendSignInLinkToExistingUser = async (email, eventId) => {
  const actionCodeSettings = {
    url: `${window.location.origin}/event/${eventId}?rsvp=true&email=${encodeURIComponent(email)}`,
    handleCodeInApp: true,
  };

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    localStorage.setItem('emailForSignIn', email);
    return { success: true, message: 'Check your email to complete your RSVP!', shouldLogin: true };
  } catch (error) {
    console.error("Error sending sign-in link:", error);
    return { success: false, message: 'Error sending sign-in link: ' + error.message };
  }
};

// Update email in RSVPs
export const updateUserEmailInRSVPs = async (userId, newEmail) => {
  const rsvpsRef = collection(db, 'rsvps');
  const q = query(rsvpsRef, where('userId', '==', userId));
  const querySnapshot = await getDocs(q);

  const batch = writeBatch(db);
  querySnapshot.forEach((doc) => {
    batch.update(doc.ref, { email: newEmail });
  });

  await batch.commit();
};

// Update email in events
export const updateUserEmailInEvents = async (userId, newEmail) => {
  const eventsRef = collection(db, 'events');
  const q = query(eventsRef, where('createdBy', '==', userId));
  const querySnapshot = await getDocs(q);

  const batch = writeBatch(db);
  querySnapshot.forEach((doc) => {
    batch.update(doc.ref, { createdByEmail: newEmail });
  });

  await batch.commit();
};

// Function to handle user profile by UID
export const getUserProfileByUid = async (uid) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() };
    }
    return null;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};
