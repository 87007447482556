import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ShareButtons from '../events/ShareButtons';
import { formatDateTime, isPastEvent } from '../utils/dateUtils';
import UserAvatar from '../users/UserAvatar';
import { useAuth } from '../Auth';
import { useNotification } from '../components/NotificationContext';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import DOMPurify from 'dompurify';
import ConfirmationModal from '../components/ConfirmationModal';
import { checkRSVP } from '../rsvps/rsvpService';
import { getInitials } from '../utils/imageUtils';
import { collection, query, where, getDocs } from 'firebase/firestore';
import EditEventModal from './EditEventModal';

const EventCard = ({ 
  event, 
  mode = 'list', 
  onEdit, 
  onDelete, 
  onFilterChange, 
  activeFilters = { myEvents: false, rsvpd: false, private: false, groups: [] },
  disableFiltering = false
}) => {
  const isDetailMode = mode === 'detail';
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isRSVPd, setIsRSVPd] = useState(event.rsvpId !== null);
  const [showMenu, setShowMenu] = useState(false);
  const [groupNames, setGroupNames] = useState([]);
  const groupsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const menuRef = useRef(null);
  const [groupInfo, setGroupInfo] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const isEventCreator = user && (event.createdBy === user.uid || event.createdBy === user.email);

  useEffect(() => {
    const checkUserRSVP = async () => {
      if (user) {
        const rsvpStatus = await checkRSVP(event.id, user.email);
        setIsRSVPd(rsvpStatus);
      }
    };
    checkUserRSVP();
  }, [user, event.id]);

  useEffect(() => {
    const fetchGroupInfo = async () => {
      if (event.groupIds && event.groupIds.length > 0) {
        const groupsRef = collection(db, 'groups');
        const q = query(groupsRef, where('eventIds', 'array-contains', event.id));
        const querySnapshot = await getDocs(q);
        const info = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          url: doc.data().url
        }));
        setGroupInfo(info);
      }
    };

    fetchGroupInfo();
  }, [event.id, event.groupIds]);

  useEffect(() => {
    const fetchGroupNames = async () => {
      if (event.groupIds && event.groupIds.length > 0) {
        const groupsRef = collection(db, 'groups');
        const q = query(groupsRef, where('eventIds', 'array-contains', event.id));
        const querySnapshot = await getDocs(q);
        const names = querySnapshot.docs.map(doc => doc.data().name);
        setGroupNames(names);
      }
    };

    fetchGroupNames();
  }, [event.id, event.groupIds]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEditModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'events', event.id));
      showNotification('Event deleted successfully', 'success');
      if (onDelete) {
        onDelete(event.id);
      } else {
        navigate('/my-events');
      }
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting event: ', error);
      showNotification('Error deleting event: ' + error.message, 'error');
    }
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const truncateDescription = (description, maxLength = 100) => {
    const strippedDescription = stripHtmlTags(description);
    if (strippedDescription.length <= maxLength) return strippedDescription;
    return strippedDescription.slice(0, maxLength) + '...';
  };

  const handleMenuItemClick = (action) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMenuOpen(false);
    action(e);
  };

  const handleFilterClick = (filterType, value) => {
    if (mode === 'detail' || disableFiltering) return; // Don't filter in detail mode or if filtering is disabled
    onFilterChange(filterType, value);
  };

  const eventIsPast = isPastEvent(event.endDate, event.endTime);

  const renderEventImage = () => (
    <div className={`relative overflow-hidden ${
      isDetailMode 
        ? 'pb-48 md:pb-64 lg:pb-96' // Taller for detail view
        : 'pb-48 md:pb-56' // Standard height for grid/list view
    }`}>
      <Link to={`/event/${event.url}`} className="absolute inset-0">
        {event.imageUrl ? (
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={event.imageUrl}
            alt={event.name}
            onError={(e) => {
              e.target.onerror = null;
              e.target.style.display = 'none';
              e.target.parentNode.textContent = event.name;
            }}
          />
        ) : (
          <div className="absolute inset-0 bg-gray-300 flex items-center justify-center text-white font-bold text-xl">
            {event.name}
          </div>
        )}
      </Link>
      <div className="absolute top-2 left-2 flex space-x-2" style={{ zIndex: 'var(--z-content)' }}>
        {event.isMyEvent && (
          <span className="px-3 py-1 text-xs font-semibold rounded-full shadow-md bg-blue-600 text-white">
            Created
          </span>
        )}
        {event.isRSVPd && user && (
          <span className="px-3 py-1 text-xs font-semibold rounded-full shadow-md bg-green-600 text-white">
            RSVP'd
          </span>
        )}
        {event.isPrivate && (
          <span className="px-3 py-1 text-xs font-semibold rounded-full shadow-md bg-gray-600 text-white">
            Private
          </span>
        )}
      </div>
      <div className="absolute bottom-2 left-2" onClick={(e) => e.preventDefault()}>
        <ShareButtons event={event} />
      </div>
      {isEventCreator && renderThreeDotMenu()}
      {eventIsPast && (
        <div className="absolute top-0 right-0" style={{ zIndex: 'calc(var(--z-content) - 1)' }}>
          <div
            className="relative text-center text-white bg-red-600 transform rotate-45"
            style={{
              width: '150px',
              height: '30px',
              top: '20px',
              right: '-40px',
              fontSize: '12px',
              fontWeight: 'bold',
              lineHeight: '30px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
            }}
          >
            Past
          </div>
        </div>
      )}
    </div>
  );

  const onMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - groupsRef.current.offsetLeft);
    setScrollLeft(groupsRef.current.scrollLeft);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - groupsRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Scroll-fast
    groupsRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleGroupClick = (groupName, e) => {
    if (isDetailMode) return; // Don't do anything if in detail mode
    e.preventDefault();
    e.stopPropagation();
    onFilterChange('groups', groupName);
  };

  const renderGroups = () => {
    if (groupInfo.length === 0 && !event.isPrivate) return null;
    
    return (
      <div className="mt-2 mb-4 w-full">
        <div className="flex flex-wrap gap-1">
          {groupInfo.map((group) => (
            <Link
              key={group.id}
              to={`/group/${group.url}`}
              className="px-2 py-1 text-xs font-medium rounded-full whitespace-nowrap bg-blue-100 text-blue-800 hover:bg-blue-200 transition-colors duration-200 flex-shrink-0"
              onClick={(e) => e.stopPropagation()} // Prevent event card click when clicking on group pill
            >
              {group.name}
            </Link>
          ))}
        </div>
      </div>
    );
  };

  const renderThreeDotMenu = () => (
    <div className="absolute bottom-2 right-2" ref={menuRef}>
      <button
        onClick={toggleMenu}
        className="bg-gray-600 text-white w-8 h-8 flex items-center justify-center rounded-full hover:bg-opacity-100 transition-all duration-200"
      >
        <i className="fas fa-ellipsis-v"></i>
      </button>
      {isMenuOpen && (
        <div className="absolute right-0 bottom-full mb-2 w-32 bg-white rounded-md shadow-lg py-1 z-50">
          <button
            onClick={handleEdit}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Edit Event
          </button>
          <button
            onClick={() => setShowDeleteModal(true)}
            className="block w-full text-left px-4 py-2 text-sm text-red-700 hover:bg-red-100"
          >
            Delete Event
          </button>
        </div>
      )}
    </div>
  );

  const handleEventSaved = (updatedEvent) => {
    setIsEditModalOpen(false);
    if (onEdit) {
      onEdit(updatedEvent);
    }
  };

  console.log(`EventCard for ${event.id}:`, { isRSVPd: event.isRSVPd, rsvpId: event.rsvpId });

  return (
    <>
      <div className={`bg-white shadow-md rounded-lg overflow-hidden ${isDetailMode ? '' : 'hover:shadow-lg transition-shadow duration-300'}`}>
        {renderEventImage()}
        <div className="p-4 flex-grow flex flex-col justify-between relative">
          <div>
            <div className="flex items-center mb-2">
              <Link
                to={`/event/${event.url}`}
                className="text-xl font-semibold text-blue-800 hover:text-blue-600 truncate"
                title={event.name}
              >
                {event.name}
              </Link>
            </div>
            <p className="text-sm text-gray-600 mb-1 flex items-center">
              <i className="fas fa-calendar-alt mr-2 text-blue-500"></i>
              {formatDateTime(event.startDate, event.startTime, event.endDate, event.endTime, event.timezone)}
            </p>
            <p className="text-sm text-gray-600 mb-2 flex items-center">
              <i className="fas fa-map-marker-alt mr-2 text-red-500"></i>
              {event.locationName && event.location ? (
                <>
                  <a 
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.location)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 hover:underline"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {event.locationName}
                  </a>
                </>
              ) : (
                event.locationName || event.location
              )}
            </p>
            <p className="text-sm text-gray-600 mb-2 flex items-center">
              <i className="fas fa-user mr-2 text-green-500"></i>
              Hosted by {event.hostName || 'Anonymous'}
            </p>
            {mode === 'detail' ? (
              <div 
                className="text-sm text-gray-600 mt-2 event-description"
                dangerouslySetInnerHTML={{ 
                  __html: DOMPurify.sanitize(event.description, {
                    ADD_ATTR: ['target', 'rel', 'src', 'alt', 'class', 'style', 'colspan', 'rowspan'],
                    ALLOWED_TAGS: [
                      'p', 'a', 'ul', 'ol', 'li', 
                      'strong', 'em', 'h1', 'h2', 'h3', 
                      'blockquote', 'img', 'figure', 'figcaption',
                      'table', 'thead', 'tbody', 'tr', 'td', 'th'
                    ],
                    ALLOWED_ATTR: [
                      'href', 'target', 'rel', 'src', 'alt', 
                      'class', 'style', 'colspan', 'rowspan'
                    ]
                  })
                }}
              />
            ) : (
              <p className="text-sm text-gray-600 mt-2">
                {truncateDescription(event.description)}
              </p>
            )}
          </div>
          <div className="mt-2">
            {renderGroups()}
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        title="Delete Event"
        message="Are you sure you want to delete this event? This action cannot be undone."
      />
      <EditEventModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        event={event}
        onEventSaved={handleEventSaved}
      />
    </>
  );
};

export default EventCard;
